import React, { useEffect, useState } from "react";

const Certificates = () => {
	const certificates = {
		afc: {
			name: "afc",
		},
		apfs: {
			name: "Apfs",
		},
		csc: {
			name: "csc",
		},
		england: {
			name: "england",
		},
		firstAid: {
			name: "firstAid",
		},
		nasm: {
			name: "nasm",
		},
	};

	return (
		<div className="text-white mt-20 content-center max-w-full mx-auto">
			<h1 className="text-5xl font-bold text-white mb-20 ml-24">
				Certifications
			</h1>
			<div className="flex flex-wrap gap-10 justify-center">
				{Object.entries(certificates).map(([key, value]) => {
					return (
						<div
							key={key}
							id={key}
							className="flex-shrink-0  justify-center w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
						>
							<img
								className="w-1/2 h-auto object-contain"
								src={require(`../images/certs/${value.name}.png`)}
								alt={`${value.name} certification`}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Certificates;
