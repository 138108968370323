import React, { useEffect, useState } from "react";

const Education = () => {
	const education = [
		"Anatomy & Physiology",
		"Biomechanics",
		"Exercise Physiology Nutrition",
		"Principles of S&C",
	];
	const skills = [
		"PowerBI",
		"C++",
		"R",
		"Data Structures and Algorithms",
		"Statistics",
	];

	return (
		<div className="mt-20 content-center w-full">
			<div className="text-neon mt-30 flex justify-center">
				<img
					className="w-1/5 rounded-lg"
					src={require("../images/Depauw.png")}
				/>
				<div className="block">
					<h1 className="text-white ml-10 font-bold text-5xl">
						Education
					</h1>
					<h1 className="ml-10 mt-10 text-2xl">Kinesiology Major</h1>
					<h1 className="ml-10 mt-5 text-2xl">Data Science Minor</h1>
				</div>
			</div>
			<div className="text-white mt-20 flex justify-center gap-10">
				<img
					className="w-1/4 rounded-lg"
					src={require("../images/3.png")}
				/>
				<div>
					<h1 className="text-4xl font-bold text-neon">
						Kinesiology Courses:
					</h1>
					<ul className="mt-5 ml-10 text-lg">
						{education.map((course) => {
							return (
								<li className="list-disc text-xl font-bold">
									{course}
								</li>
							);
						})}
					</ul>
				</div>
				<div>
					<img
						className="h-60 rounded-lg"
						src={require("../images/4.png")}
					/>
					<h1 className="text-4xl font-bold text-neon">
						Data Science Skills:
					</h1>
					<ul className="mt-5 ml-10">
						{skills.map((course) => {
							return (
								<li className="list-disc text-xl font-bold">
									{course}
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Education;
