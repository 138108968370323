import React, { useEffect, useState } from "react";

const TopPage = () => {
	const aboutMe = [
		"South Korean ",
		"Grew up in Orange County USA, Bristol UK",
		"Passionate towards football (soccer) performance",
		"School : DePauw University ",
	];

	const socialMediaLinks = {
		linkedin: {
			name: "fa fa-brands fa-linkedin fa-fw",
			href: "https://www.linkedin.com/in/hamin-kwon-32bb2b19b/",
		},
		instagram: {
			name: "fa fa-brands fa-instagram fa-fw",
			href: "https://www.instagram.com/koreanfooti/",
		},
		mail: {
			name: "fa fa-solid fa-paper-plane fa-fw",
			href: `mailto:koreanfootballplayer@gmail.com`,
		},
		resume: {
			name: "fa fa-solid fa-file fa-fw",
			href: "https://drive.google.com/file/d/1TXc8FN2wGoZtyOVcGPnypRxDt0p9KIKG/view?usp=sharing",
		},
	};

	return (
		<div className="font-bold text-ellipsis sm:text-base ml-44 mt-44 flex">
			<img
				className="rounded-full w-1/4"
				src={require("../images/humanPerformance.png")}
			/>

			<div className="font-semibold flex-grow flex flex-col justify-center ml-52">
				<div className="text-7xl text-white">About Me:</div>

				<ul className="list-disc pl-6 text-2xl text-white mt-10">
					{aboutMe.map((item) => {
						return <li className="list-disc">{item}</li>;
					})}
				</ul>

				<div className="flex gap-3 text-neon text-3xl mt-5">
					{Object.keys(socialMediaLinks).map((linkItem, i) => {
						return (
							<a
								key={i}
								href={socialMediaLinks[linkItem]["href"]}
								className={socialMediaLinks[linkItem]["name"]}
							></a>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default TopPage;
