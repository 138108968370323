import TopPage from "./Components/TopPage";
import Education from "./Components/Education";
import Experience from "./Components/Experience";
import Certificates from "./Components/Certificates";
import Blogs from "./Components/Blogs";
import ContactMe from "./Components/ContactMe";
import ImageList from "./Components/ImageList";
import "./App.css";

function App() {
	return (
		<>
			<TopPage />
			<Education />
			<Experience />
			<Certificates />
			<Blogs />
			<ImageList />
			<ContactMe />
		</>
	);
}

export default App;
