import React, { useEffect, useState } from "react";

const Experience = () => {
	const experience = [
		{
			name: "Assistant S & C Coach",
			image: "STVFC.png",
		},
		{
			name: "Assistant S & C Coach",
			image: "PB.png",
		},
		{
			name: "Athletic Performance Intern",
			image: "IU.png",
		},
		{
			name: "Sport Science Intern",
			image: "nashville.png",
		},
		{
			name: "Business Development Intern",
			image: "vald.png",
		},
		{
			name: "Sports Science Intern",
			image: "indyEleven.png",
		},
	];

	return (
		<div className="text-white mt-20 text-3xl w-11/12 ml-auto mr-auto">
			<h1 className="mb-20 font-bold ml-28 text-5xl">
				Professional Experience
			</h1>
			<div className="w-full flex justify-center">
				{experience.map((exp, index) => {
					return (
						<div className="flex items-center mb-5">
							<div>
								<img
									className="object-cover rounded-lg w-4/5 h-full hover:scale-110 transition-all"
									src={require(`../images/${exp.image}`)}
									alt={exp.name}
									style={{
										objectFit: "cover",
										width: "100%",
										height: "100%",
									}}
								/>
								<p className="ml-3 mt-5 font-bold text-xl">
									{exp.name}
								</p>
							</div>
							{index === experience.length - 1 ? (
								<></>
							) : (
								<i className="fa-solid fa-arrow-right ml-2 mr-2"></i>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Experience;
