import React, { useEffect, useState } from "react";
import GlowingStarsBackgroundCard from "./ui/glowingStars.jsx";
import HoverEffect from "./ui/hoverEffect.jsx";

const Certificates = () => {
	const blogs = {
		sc: {
			name: "Strength & Conditioning",
			description:
				"Articles and tips on improving strength and conditioning for athletes.",
			link: "https://www.canva.com/design/DAFflKCxoCQ/YqSpmWHq4-9jfBB5KNSBJA/edit",
		},
		ss: {
			name: "Sports Science - Heart Rate Monitoring",
			description:
				"Insights and research findings on the science behind sports performance.",
			link: "https://www.canva.com/design/DAFmZEwK6sU/sUVH2kF_DYPn5fJUWzLJMg/edit",
		},
		nutrition: {
			name: "Nutrition",
			description:
				"Guidance and advice on optimizing nutrition for Nutrition performance.",
		},
		testing: {
			name: "Counter Movement Jump",
			description:
				"Strategies and techniques for preventing injuries in Counter Movement Jump.",
			link: "https://www.canva.com/design/DAFmZsQTzdc/uY0NTdfCs_fCwfHIP7SVJA/edit",
		},
		soccer: {
			name: "Soccer",
			description:
				"Analysis, tactics, and updates on the world of Soccer.",
		},
		indyEleven: {
			name: "Indy Eleven Performance Packet",
			description:
				"Exclusive content tailored for athletes focusing on Indy Eleven Performance Packet improvement.",
		},
	};

	return (
		<div className="text-white mt-20 content-center max-w-full mx-auto mb-11">
			<h1 className="text-5xl font-bold text-white underline mb-20 ml-24">
				<a href="https://medium.com/@koreanfooti">Blogs</a>
			</h1>

			<div className="flex flex-wrap gap-10 justify-center">
				{Object.entries(blogs).map(([key, value]) => {
					return (
						<div
							key={key}
							id={key}
							className="
            flex-shrink-0 
            w-full md:w-1/2 lg:w-1/3 xl:w-1/4 h-64 bg-gray-200 
            text-black flex items-center justify-center 
            p-4 border box-border rounded-2xl border-neon"
						>
							<div className="text-center">
								<a href={value.link}>
									<h3 className="text-xl lg:text-2xl underline mb-5 font-bold">
										{value.name}
									</h3>
								</a>

								<h3 className="text-lg">{value.description}</h3>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default Certificates;
