import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import React from "react";

const ImageListComponent = () => {
	const itemData = [
		{
			img: "example1.png",
			title: "Vald One",
		},
		{
			img: "haminIU.png",
			title: "Vald Two",
		},
		{
			img: "haminIUTwo.png",
			title: "Vald Three",
		},
		{
			img: "example3.png",
			title: "Vald Four",
		},
		{
			img: "haminArmy.png",
			title: "Vald Five",
		},
	];
	return (
		<div className="justify-center w-full">
			<h1 className="text-white font-bold text-4xl mb-20 mt-20 ml-20">
				Previous Internship Experience Gallery:
			</h1>
			<div className="w-11/12 flex justify-center ml-auto mr-auto">
				<ImageList
					sx={{ width: Window.width, height: 500 }}
					variant="woven"
					cols={5}
					gap={8}
					className="flex items-center mb-5"
				>
					{itemData.map((item) => (
						<ImageListItem>
							<img
								src={require(`../images/${item.img}`)}
								loading="lazy"
								className="w-full rounded-md"
							/>
						</ImageListItem>
					))}
				</ImageList>
			</div>
		</div>
	);
};

export default ImageListComponent;
