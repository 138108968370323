const ContactMe = () => {
	return (
		<div className="w-full flex justify-center items-center p-10 mt-20 mb-20">
			<a
				className="ml-auto mr-auto btn text-7xl font-bold border-opacity-5 text-white mt-20 mb-20 border-4 p-5 rounded-lg border-red-50 md:text email-link hover:scale-110 transition-all"
				href={`mailto:koreanfootballplayer@gmail.com`}
			>
				Contact Me
			</a>
		</div>
	);
};

export default ContactMe;
